import {ReactComponent as Security} from "../../../assets/images/svg/Security.svg";
import {ReactComponent as VehiclePortfolio} from "../../../assets/images/svg/VehiclePortfolio.svg";
import {ReactComponent as Accesible} from "../../../assets/images/svg/Accesible.svg";
import {ReactComponent as Scan} from "../../../assets/images/svg/Scan.svg";
import {ReactComponent as Insured} from "../../../assets/images/svg/Insured.svg";

export const features = [
    {
        title: 'features.security',
        description: 'features.securityDescription',
        icon: Security,
    },
    {
        title: 'features.portfolio',
        description: 'features.portfolioDescription',
        icon: VehiclePortfolio,
    },
    {
        title: 'features.accesibility',
        description: 'features.accesibilityDescription',
        icon: Accesible,
    },
    {
        title: 'features.easyToUse',
        description: 'features.easyToUseDescription',
        icon: Security,
    },
    {
        title: 'features.scanning',
        description: 'features.scanningDescription',
        icon: Scan,
    },
    {
        title: 'features.policyHolder',
        description: 'features.policyHolderDescription',
        icon: Insured,
    },
]
