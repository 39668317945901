export const links = [
    {
        title: 'headerNav.home',
        to: '/home',
    },
    {
        title: 'headerNav.files',
        to: '/AccidentFiles',
    },
    {
        title: 'headerNav.tutorial',
        to: '/tutorial',
    },
    {
        title: 'headerNav.insurers',
        absoluteTo: 'https://asig.amiabila.com',
    },
    {
        title: 'headerNav.membrii',
        to: '/membrii',
    },
]

export const footerLinks = [
    ...links,
    {
        title: 'footerNav.terms',
        to: '/termenisiconditiisiinformareprelucraredcp',
    },
    {
        title: 'headerNav.deleteUser',
        to: '/DeleteUser',
    },
]
