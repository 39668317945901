import BasePage from "../base-page/BasePage";
import {ReactComponent as CircleTickTransparent} from '../../assets/images/svg/CircleTickTransparent.svg';
import {ReactComponent as CircleErrorTransparent} from '../../assets/images/svg/CircleErrorTransparent.svg';
import styles from './styles/verifyemailtokenb.module.css';
import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import validate_accident_token_b_by_token from "../../helpers/api/validate_accident_token_b_by_token";

const VerifyEmailTokenB = () => {
    const [feedback, setFeedback] = useState();
    const [searchParams] = useSearchParams();

    const validate = async () => {
        try {
            const token = searchParams.get("token");
            if (token) {
                await validate_accident_token_b_by_token(token);
                setFeedback('success');
            }
        } catch (e) {
            setFeedback(e);
        }
    }

    useEffect(() => {
        void validate();
    }, [])

    return (
        <BasePage>
            <div className={`flex-container flex-grow ${styles.successContainer}`}>
                {feedback && (
                    feedback === 'success' ?
                        <>
                            <CircleTickTransparent className={styles.successIcon}/>
                            <h1 className={styles.successTitle}>Felicitări!</h1>
                            <p className={styles.successDescription}>Ai validat cu succes constatarea! Poți părăsi
                                această
                                fereastră.</p>
                        </>
                        :
                        <>
                            <CircleErrorTransparent className={styles.successIcon}/>
                            <h1 style={{color: 'red'}} className={styles.successTitle}>Eroare</h1>
                            <p className={styles.successDescription}>{feedback.message}</p>
                        </>
                )}
            </div>
        </BasePage>
    )
}

export default VerifyEmailTokenB;
