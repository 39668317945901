import styles from './styles/button.module.css';

const Button = ({color = 'rgba(242, 81, 38, 1)', text, children, style = {}, onPress, className}) => {
    return (
        <button onClick={onPress} style={{...style, backgroundColor: color}} className={`${styles['button-container']} ${className ?? ''}`}>
            {children || <p className={styles['button-text']}>{text}</p>}
        </button>
    )
}

export default Button;
