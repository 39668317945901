import styles from "./styles.module.css";
import {t} from "i18next";

const Support = () => {
    return (
        <div className={styles['container']}>
            <div className={styles['content-container']}>
                <div className={styles['header']}>
                    <img src={require('../../assets/images/png/logo.png')} alt="logo" style={{borderRadius: '20px'}}/>
                    <h2 className={styles['app-title']}>Amiabila Help Center</h2>
                    <p className={styles['contact-email']}>contact@amiabila.com</p>
                    <h3 className={styles['first-description']}>
                        {t('support.title')}
                    </h3>
                </div>
                <form className={styles['contact-form']}>
                    <label>Nume</label>
                    <input type="text" name="name" placeholder={"Nume"}/>
                    <label>Email</label>
                    <input type="email" name="email" placeholder={"Email"}/>
                    <label>Problemă</label>
                    <textarea placeholder={"Problemă"}/>

                    <input type="submit" value="Trimite" className={styles['submit-btn']}/>
                </form>
                <div className={styles['contact-physically']}>
                    <p style={{color: 'white'}}>Date contact fizice:</p>
                    <p style={{color: 'white'}}>{
                        "Adresa: Str. Vasile Lascar nr. 40-40 bis, etaj 5 si 6, sector 2, Bucuresti, cod postal 020502, " +
                        "Romania\n\n" +
                        "Program Call Center:\nLuni - Joi: 09:00 - 16:00\n" +
                        "Vineri: 09:00 - 13:00\n" +
                        "Sambata-Duminica: INCHIS"}
                    </p>
                </div>
            </div>
            <a style={{color: 'white', justifySelf: 'flex-end'}} href={"https://amiabila.com"}>amiabila.com</a>
        </div>
    )
}

export default Support;
