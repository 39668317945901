import formStyles from "../../../../styles/form.module.css";
import {t} from "i18next";

const CodeFormSection = ({code, setCode}) => (
    <div className={formStyles['form-container']}>
        <h4 className={formStyles['label']}>{t("_.confirmationCode")}</h4>
        <input placeholder={t("_.confirmationCode")}
               className={formStyles['codeInput']}
               type="text"
               value={code} onChange={(e) => setCode(e.target.value)}
        />
    </div>
)

export default CodeFormSection;
