import custom_fetch from "../custom_fetch";
import {BASE_URL} from "./base_url";

const confirm_delete_user_by_token = (token) => {
    const url = `${BASE_URL}/Users/ConfirmDeleleUserByWebToken?Token=${encodeURIComponent(token)}`;
    return custom_fetch(url, {
        method: 'POST'
    }).then(data => {
        return data;
    }).catch(error => {
        throw error;
    })
}

export default confirm_delete_user_by_token;
