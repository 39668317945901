import {BrowserRouter, Route, Routes} from "react-router-dom";
import LandingPage from "./pages/landing-page/LandingPage";
import Home from "./pages/home/Home";
import AccidentFiles from "./pages/accidents-files/AccidentsFiles";
import DeleteUser from "./pages/delete-user/DeleteUser";
import Tutorial from "./pages/tutorial/Tutorial";
import VerifyEmailTokenB from "./pages/verify-email-token-b/VerifyEmailTokenB";
import VerifyAppEmail from "./pages/verify-app-email/VerifyAppEmail";
import {withTranslation} from "react-i18next";
import 'react-phone-number-input/style.css';
import TermsAndConditions from "./pages/terms-and-conditions/TermsAndConditions";
import ResetPassword from "./pages/reset-password/ResetPassword";
import Membrii from "./pages/membrii/membrii";
import QRPage from "./pages/qr/QRPage";
import Support from "./pages/support/Support";

function App() {
    return (
        <BrowserRouter>
            <RoutesComponent/>
        </BrowserRouter>
    );
}

const RoutesComponent = () => (
    <Routes>
        <Route path="/" element={<LandingPage/>}/>
        <Route path="/home" element={<Home/>}/>
        <Route path="/AccidentFiles" element={<AccidentFiles/>}/>
        <Route path="/DeleteUser" element={<DeleteUser/>}/>
        <Route path="/tutorial" element={<Tutorial/>}/>
        <Route path="/VerifyTokenB" element={<VerifyEmailTokenB/>}/>
        <Route path="/verify/:verificationToken" element={<VerifyAppEmail/>}/>
        <Route path="/reset/:resetToken" element={<ResetPassword/>}/>
        <Route path="/termenisiconditiisiinformareprelucraredcp" element={<TermsAndConditions/>}/>
        <Route path="/membrii" element={<Membrii/>}/>
        <Route path="/qr" element={<QRPage/>}/>
        <Route path="/support" element={<Support/>}/>
    </Routes>
)


export default withTranslation()(App);
