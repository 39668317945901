import Button from "../../../components/button/Button";
import {t} from "i18next";
import {useState} from "react";
import formStyles from '../../../styles/form.module.css';
import reset_password from "../../../helpers/api/reset_password";

const ResetPasswordForm = ({setFeedback, resetToken}) => {
    const [newPassword, setNewPassword] = useState();
    const [confirmNewPassword, setConfirmNewPassword] = useState();

    const handleSubmit = async (e) => {
        try {
            setFeedback();
            e.preventDefault();
            if (newPassword !== confirmNewPassword) {
                setFeedback({status: 'error', message: t('resetPass.passwordMatch')});
                return;
            }
            await reset_password(newPassword, resetToken);
            setFeedback('success');
        } catch (e) {
            setFeedback({message: e.message, status: 'error'});
        }
    }

    return (
        <form className={formStyles['form']} onSubmit={handleSubmit}>
            <div className={formStyles['form-container']}>
                <h4 className={formStyles['label']}>{t("resetPass.newPass")}</h4>
                <input placeholder={t("resetPass.newPass")}
                       className={formStyles['codeInput']}
                       type="password"
                       value={newPassword} onChange={(e) => setNewPassword(e.target.value)}
                />

                <h4 className={formStyles['label']} style={{marginTop: '2rem'}}>{t("resetPass.confirmNewPass")}</h4>
                <input placeholder={t("resetPass.confirmNewPass")}
                       className={formStyles['codeInput']}
                       type="password"
                       value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)}
                />
            </div>
            <Button color={'rgba(73, 142, 245, 1)'}
                    text={t('_.confirm')}
                    style={{borderRadius: '4px'}}
            />
        </form>
    )
}

export default ResetPasswordForm;
