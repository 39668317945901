import ChooseLanguage from "../ChooseLanguage/ChooseLanguage";
import Links from "./Links";

const Navlinks = ({links, containerClass = "", linkClass = "", type = "header"}) => {
    return (
        <div className={containerClass}>
            <Links links={links} linkClass={linkClass}/>
            {type === "header" &&
                <ChooseLanguage/>
            }
        </div>
    )
}

export default Navlinks;
