import BasePage from "../base-page/BasePage";
import {ReactComponent as CircleTickTransparent} from '../../assets/images/svg/CircleTickTransparent.svg';
import {ReactComponent as CircleErrorTransparent} from '../../assets/images/svg/CircleErrorTransparent.svg';
import styles from '../verify-email-token-b/styles/verifyemailtokenb.module.css';
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import verify_email from "../../helpers/api/verify_email";

const VerifyAppEmail = () => {
    const [feedback, setFeedback] = useState();
    const {verificationToken} = useParams();

    const validate = async () => {
        try {
            if (verificationToken) {
                await verify_email(verificationToken);
                setFeedback('success');
            }
        } catch (e) {
            setFeedback(e);
        }
    }

    useEffect(() => {
        void validate();
    }, [])

    return (
        <BasePage>
            <div className={`flex-container flex-grow ${styles.successContainer}`}>
                {feedback && (
                    feedback === 'success' ?
                        <>
                            <CircleTickTransparent className={styles.successIcon}/>
                            <h1 className={styles.successTitle}>Felicitări!</h1>
                            <p className={styles.successDescription}>Ai confirmat cu succes adresa de email! Te poți intoarce in aplicație.</p>
                        </>
                        :
                        <>
                            <CircleErrorTransparent className={styles.successIcon}/>
                            <h1 style={{color: 'red'}} className={styles.successTitle}>Eroare</h1>
                            <p className={styles.successDescription}>{feedback.message}</p>
                        </>
                )}
            </div>
        </BasePage>
    )
}

export default VerifyAppEmail;
