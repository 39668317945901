import custom_fetch from "../custom_fetch";
import {BASE_URL} from "./base_url";

const get_web_token_by_phone_and_code = (mobile, code) => {
    const url = `${BASE_URL}/Accidents/GetWebTokenByPhoneAndCode?Phone=${encodeURIComponent(mobile)}&Code=${code}`;
    return custom_fetch(url, {
        method: 'POST'
    }).then(data => {
        return data;
    }).catch(error => {
        throw error;
    })
}

export default get_web_token_by_phone_and_code;
