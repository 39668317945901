import custom_fetch from "../custom_fetch";
import {BASE_URL} from "./base_url";

const verify_email = (token) => {
    const url = `${BASE_URL}/Auth/Verify?verificationToken=${encodeURIComponent(token)}`;
    return custom_fetch(url, {
        method: 'POST'
    }).then(data => {
        return data;
    }).catch(error => {
        throw error;
    })
}

export default verify_email;
