import {useState} from "react";
import Button from "../../../../components/button/Button";
import formStyles from "../../../../styles/form.module.css";
import {forms} from "./forms";
import IdentificationFormSection from "./IdentificationFormSection";
import send_delete_user_web_token_by_email from "../../../../helpers/api/send_delete_user_web_token_by_email";
import {t} from "i18next";

const DeleteUserForm = ({setError}) => {
    const [formType, setFormType] = useState("email");
    const [input, setInput] = useState("");

    const handleSubmitIdentification = async (e) => {
        try {
            e.preventDefault();
            await send_delete_user_web_token_by_email(input);
            setFormType('tokenFromEmail');
        } catch (e) {
            setError(e);
        }
    }

    return (
        <>
            {formType === 'tokenFromEmail' ?
                <p style={{marginTop: '2rem'}}>{t('deleteUser.sentCodeEmail')}</p>
                :
                <form className={formStyles['form']}
                      onSubmit={handleSubmitIdentification}>
                        <IdentificationFormSection input={input} setInput={setInput}/>
                   
                    <Button color={forms[formType].buttonColor}
                            text={t(forms[formType].buttonText)}
                            style={{borderRadius: '4px'}}
                    />

                    <p style={{color: "red", marginTop: '.5rem'}}>{t('deleteUser.deleteNotice')}</p>
                </form>
            }
        </>
    )
}

export default DeleteUserForm;
