import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import './styles/base.css';
import useScrollToTop from "../../hooks/useScrollToTop";

const BasePage = ({children}) => {
    useScrollToTop();
    return (
        <div className="main-container">
            <Header/>
            {children}
            <Footer/>
        </div>
    )
}

export default BasePage;
