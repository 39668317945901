import {useState} from "react";
import Button from "../../../../components/button/Button";
import formStyles from "../../../../styles/form.module.css";
import {forms} from "./forms";
import IdentificationFormSection from "./IdentificationFormSection";
import confirm_delete_user_by_token from "../../../../helpers/api/confirm_delete_user_by_token";
import get_accidents_by_token from "../../../../helpers/api/get_accidents_by_token";
import {cleanAccidents} from "../../../../helpers/cleanAccidents";
import {t} from "i18next";
import {setSessionTimeout} from "../../helpers/setSessionTimeout";

const DeleteUserConfirmForm = ({setError}) => {
    const [formType, setFormType] = useState("confirmDelete");
    const [input, setInput] = useState("");

    const handleSubmitIdentification = async (e) => {
        try {
            e.preventDefault();
            await confirm_delete_user_by_token(sessionStorage.getItem("deleteUserToken"));
            sessionStorage.removeItem('deleteUserToken');
            setFormType('confirmationProcesed');
        } catch (e) {
            setError(e);
        }
    }

    return (
        <>
            {formType === 'confirmationProcesed' ?
                <p style={{marginTop: '2rem'}}>{t('deleteUser.confirmDeleteCompleted')}</p>
                :
                <form className={formStyles['form']}
                      onSubmit={handleSubmitIdentification}>

                    <Button color={forms[formType].buttonColor}
                            text={t(forms[formType].buttonText)}
                            style={{borderRadius: '4px'}}      
                    />
                </form>
            }
        </>
    )
}

export default DeleteUserConfirmForm;
