import {useEffect} from "react";
import styles from './styles.module.css';
import {ReactComponent as Share} from "../../assets/images/svg/Share.svg";
import {APPLE_STORE, PLAY_STORE} from "../../utils/storeLinks";
import {t} from "i18next";
import {AppStoreButton, PlayStoreButton} from "../../components/StoreButtons/StoreButtons";

function detectiOS() {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const isAppleDevice = navigator.userAgent.includes('Macintosh');
    const isTouchScreen = navigator.maxTouchPoints >= 1;

    return isIOS || (isAppleDevice && isTouchScreen);
}

function getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    if (detectiOS()) {
        return "iOS";
    }

    return "unknown";
}

const QRPage = () => {
    useEffect(() => {
        switch (getMobileOperatingSystem()) {
            case "Android":
                window.location.href = PLAY_STORE;
                break;
            case "iOS":
                window.location.href = APPLE_STORE;
                break;
            default:
                break;
        }
    }, [])

    const shareData = {
        title: 'Amiabila',
        text: 'Amiabila este aplicația gratuită care digitalizează procesul unei constatări amiabile.',
        url: 'https://amiabila.com'
    }

    return (
        <div className={styles['container']}>
            <Share onClick={() => navigator.share(shareData)} width={30} height={30}
                   style={{position: 'absolute', top: 30, right: 20, cursor: 'pointer'}} fill={'white'}/>
            <div className={styles['content-container']}>
                <img src={require('../../assets/images/png/logo.png')} alt="logo" style={{borderRadius: '20px'}}/>
                <h2 className={styles['app-title']}>Amiabila</h2>
                <h3 className={styles['first-description']}>
                    {t('landing.title')}
                </h3>
                <h4 className={styles['second-description']}>
                    {t('landing.description_1') + " " + t('landing.description_2')}
                </h4>
                <PlayStoreButton style={{marginBottom: '1rem'}}/>
                <AppStoreButton style={{marginBottom: '1rem'}}/>
            </div>

            <a style={{color: 'white', justifySelf: 'flex-end'}} href={"https://amiabila.com"}>amiabila.com</a>
        </div>
    )
}

export default QRPage;
