import styles from './panels.module.css';

const Panel = ({title, description, image, direction = 'left', phoneNr, email, fax, web, linkAvizare}) => {
    return (
        <div className={styles['panel-container']}>
            <div className={styles['image-container']}>
                <img className={styles['image']} src={image} alt={"img"}/>
            </div>
            <div className={styles['text-container']} style={{order: direction === 'left' ? 0 : 1}}>
                <h1 className={styles['title']}>{title}</h1>
                <h3 className={styles['adresa']}>{description}</h3>

                {(phoneNr !== "") && <jsx><p className={styles['round3']}><b>Telefon:</b> {phoneNr}</p></jsx>}
                {(email !== "" && email !== undefined) &&
                    <p className={styles['round3']}><b>Email:</b> {email}</p>}
                {(fax !== "" && fax !== undefined) && <jsx><p className={styles['round3']}><b>Fax:</b> {fax}</p></jsx>}
                {(web !== "" && web !== undefined) &&
                    <p className={styles['round3']}><b>Web:</b> <a href={web} target="_blank"
                                                                   rel="noopener">{web}</a></p>}
                {(linkAvizare !== "" && linkAvizare !== undefined) &&
                    <p className={styles['round3']}><b>Avizare daune online:</b> <a href={linkAvizare} target="_blank"
                                                                   rel="noopener">{linkAvizare}</a></p>}

            </div>
        </div>
    )
}

export default Panel;
