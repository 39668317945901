import AccidentItem from "./AccidentItem";
import styles from './accidentlist.module.css';
import accidentFilesStyles from '../../styles/accidentfiles.module.css';
import {t} from "i18next";

const AccidentList = ({accidents, selectAccident, revokeSession}) => {
    return (
        <div>
            <div className={accidentFilesStyles['header']}>
                <div className={accidentFilesStyles['titleHeader']}>
                    <h2 className={accidentFilesStyles['title']}>{t('files.yourStatements')}</h2>
                    <h4 onClick={revokeSession}
                        style={{color: 'rgb(66, 129, 225)', cursor: 'pointer'}}>{t('_.closeSession')}</h4>
                </div>
            </div>
            <div className={accidentFilesStyles['accident-list']}>
                <div className={styles['list-container']}>
                    {accidents.map(accident => (
                        <AccidentItem key={accident.accidentID} accident={accident} selectAccident={selectAccident}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default AccidentList;
