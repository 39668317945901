import {FetchError} from "./FetchError";

const custom_fetch = (url, options) => {
    if (!options.headers) {
        options.headers = {};
    }

    // Add custom header
    options.headers['AmiabilaVersion'] = '1.0.7';  // Replace 'your-value' with the value you want

    console.log(options.headers);
    return fetch(url, options)
        .then(response => {
            if (response.ok) {
                return response.text();
            }
            return response.text().then((text) => {
                if (response.status === 500) {
                    throw new FetchError(response.status, "Internal server error");
                }
                throw new FetchError(response.status, text);
            });
        })
}

export default custom_fetch;
