import formStyles from "../../../../styles/form.module.css";
import {t} from "i18next";

const IdentificationFormSection = ({input, setInput}) => (
    <div className={formStyles['form-container']}>
            <>
                <h4 className={formStyles['label']} style={{marginTop: '2rem'}}>{"Email"}</h4>
                <input placeholder={"example@gmail.com"}
                       className={formStyles['codeInput']}
                       type="email"
                       value={input} onChange={(e) => setInput(e.target.value)}
                       required
                />
            </>

    </div>
)

export default IdentificationFormSection;
