import styles from './styles/RoundedRectangle.module.css';
import {createElement} from "react";

const RoundedRectangle = ({icon, title, description, containerClass="", iconClass=""}) => {
    return (
        <div className={`${containerClass} ${styles['container']}`}>
            <div className={styles['icon-container']}>
                {createElement(icon, {className: `${iconClass} ${styles['icon-style']}`}, null)}
            </div>
            <h2 className={styles['title']}>{title}</h2>
            <p className={styles['description']}>{description}</p>
        </div>
    )
}

export default RoundedRectangle;
