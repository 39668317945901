export const forms = {
    email: {
        inputText: 'Email / Telefon',
        buttonText: 'files.sendCode',
        buttonColor: 'rgba(73, 142, 245, 1)',
    },
    code: {
        inputText: 'Cod confirmare',
        buttonText: 'files.confirmCode',
        buttonColor: 'rgba(58, 58, 97, 1)',
    }
}
