import styles from './backbutton.module.css';
import Button from "../button/Button";

const BackButton = ({goBack}) => (
    <Button className={styles['back-button']} color={'#498EF5'} onPress={goBack}>
        <i className="material-icons" style={{color: 'white'}}>west</i>
    </Button>
)

export default BackButton;
