import custom_fetch from "../custom_fetch";
import {BASE_URL} from "./base_url";

const get_accidents_files_by_token = (accidentID, token) => {
    const url = `${BASE_URL}/Accidents/GetAccidentFilesByToken?AccidentID=${accidentID}&Token=${encodeURIComponent(token)}`;
    return custom_fetch(url, {
        method: 'GET'
    }).then(data => {
        return data;
    }).catch(error => {
        throw error;
    })
}

export default get_accidents_files_by_token;
