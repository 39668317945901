import {ReactComponent as AmiabilaWhiteLogo} from "../../assets/images/svg/AmiabilaWhiteLogo.svg";
import styles from './styles/header.module.css';
import HeaderNavlinks from "../navlinks/HeaderNavlinks";

const Header = () => {
    return (
        <div className={styles['header-container']}>
            <AmiabilaWhiteLogo className={styles['logo']}/>
            <HeaderNavlinks/>
        </div>
    )
}

export default Header;
