import styles from './panels.module.css';
import Circle from "../../../components/circle/Circle";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const Panel = ({title, description, image, step, direction = 'left'}) => {
    const {width} = useWindowDimensions();
    const CircleComponent = <Circle><h3 style={{color: 'white', fontSize: '1.5rem'}}>{step + "."}</h3></Circle>;

    return (
        <div className={styles['panel-container']}>
            {width <= 768 && CircleComponent}
            <div className={styles['text-container']} style={{order: direction === 'left' ? 0 : 1}}>
                {width > 768 && CircleComponent}
                <h1 className={styles['title']}>{title}</h1>
                <p>{description}</p>
            </div>
            <div className={styles['image-container']}>
                <img className={styles['image']} src={image} alt={"img"}/>
            </div>
        </div>
    )
}

export default Panel;
