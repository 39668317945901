import styles from './imageitem.module.css';
import {useState} from "react";
import {BASE_URL} from "../../../../helpers/api/base_url";

const ImageItem = ({fileID, openModal}) => {
    const [url] = useState(`${BASE_URL}/Accidents/GetAccidentFileByToken?FileID=${fileID}&Token=${sessionStorage.getItem('filesToken')}`);

    return (
        <div className={styles['image-container']} onClick={() => openModal(url)}>
            <img className={styles['image']} src={url} alt="media"/>
        </div>
    )
}

export default ImageItem;
