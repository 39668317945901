import FilesList from "../FilesList/FilesList";
import AccidentList from "../AccidentList/AccidentList";
import get_accidents_files_by_token from "../../../../helpers/api/get_accidents_files_by_token";
import {useState} from "react";
import Timer from "../../../../components/Timer/Timer";
import accidentFilesStyles from "../../styles/accidentfiles.module.css";
import {t} from "i18next";

const AccidentAndFilesList = ({sessionTimerCount, accidentList, revokeSession, setError}) => {
    const [selectedAccident, setSelectedAccident] = useState();

    const selectAccident = async (accidentID) => {
        try {
            const filesToken = sessionStorage.getItem("filesToken");
            const files = JSON.parse(await get_accidents_files_by_token(accidentID, filesToken));
            setSelectedAccident({
                title: `Accident ${accidentID}`,
                files
            })
        } catch (e) {
            setError(e);
        }
    }

    return (
        <>
            <div className={accidentFilesStyles['sessionExpiry']}>
                <p style={{marginRight: '.3rem'}}>{t('files.sessionExpiry')}</p>
                <Timer initialCount={sessionTimerCount} />
            </div>
            {selectedAccident ?
                <FilesList selectedAccident={selectedAccident}
                           goBack={() => setSelectedAccident()}
                />
                :
                <AccidentList accidents={accidentList}
                              selectAccident={selectAccident}
                              revokeSession={revokeSession}
                />
            }
        </>
    )
}

export default AccidentAndFilesList;
