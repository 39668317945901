import styles from './fullscreenimagemodal.module.css';

const FullScreenImageModal = ({imgURL, closeModal}) => {
    return (
        <div className={styles['container']}>
            <span onClick={closeModal} className={styles['close-btn']}>&times;</span>
                <img src={imgURL} className={styles['image']} alt={'accident img'}/>
        </div>
    )
}

export default FullScreenImageModal;
