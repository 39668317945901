import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ro from './locales/ro.json';
import en from './locales/en.json';

const DETECTION_OPTIONS = {
    order: ['localStorage', 'navigator'],
    caches: ['localStorage']
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        detection: DETECTION_OPTIONS,
        compatibilityJSON: 'v3',
        nsSeparator: false,
        keySeparator: false,
        resources: {
            ro: {
                translation: ro
            },
            en: {
                translation: en
            }
        },
        lng: localStorage.getItem("i18nextLng"),
        fallbackLng: "ro",
        interpolation: {
            escapeValue: false
        }
    });
