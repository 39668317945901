import {useState} from "react";
import Button from "../../../../components/button/Button";
import formStyles from "../../../../styles/form.module.css";
import {forms} from "./forms";
import send_web_token_code_by_phone from "../../../../helpers/api/send_web_token_code_by_phone";
import get_web_token_by_phone_and_code from "../../../../helpers/api/get_web_token_by_phone_and_code";
import IdentificationFormSection from "./IdentificationFormSection";
import CodeFormSection from "./CodeFormSection";
import send_web_token_by_email from "../../../../helpers/api/send_web_token_by_email";
import get_accidents_by_token from "../../../../helpers/api/get_accidents_by_token";
import {cleanAccidents} from "../../../../helpers/cleanAccidents";
import {t} from "i18next";
import {setSessionTimeout} from "../../helpers/setSessionTimeout";

const AccidentFilesForm = ({setAccidentList, setSessionTimerCount, setMode, setError}) => {
    const [formType, setFormType] = useState("email");
    const [input, setInput] = useState("");
    const [code, setCode] = useState("");
    const [channelChoice, setChannelChoice] = useState("email");

    const handleSubmitIdentification = async (e) => {
        try {
            e.preventDefault();
            if (channelChoice === 'email') {
                await send_web_token_by_email(input);
                setFormType('tokenFromEmail');
            } else {
                await send_web_token_code_by_phone(input);
                setFormType('code');
            }
        } catch (e) {
            setError(e);
        }
    }

    const handleSubmitCode = async (e) => {
        try {
            e.preventDefault();
            const token = await get_web_token_by_phone_and_code(input, code);
            const fixedToken = token.slice(1, -1);
            const accidents = cleanAccidents(JSON.parse(await get_accidents_by_token(fixedToken)));
            setSessionTimeout(setSessionTimerCount, setMode);
            sessionStorage.setItem("filesToken", fixedToken);
            setMode('list');
            setAccidentList(accidents);
        } catch (e) {
            setError(e);
        }
    }

    const onSelectChange = (e) => {
        setInput("");
        setChannelChoice(e.target.value);
    }

    return (
        <>
            {formType === 'tokenFromEmail' ?
                <p style={{marginTop: '2rem'}}>{t('files.sentCodeEmail')}</p>
                :
                <form className={formStyles['form']}
                      onSubmit={formType === 'email' ? handleSubmitIdentification : handleSubmitCode}>
                    {formType === 'email' ?
                        <IdentificationFormSection input={input} setInput={setInput} onSelectChange={onSelectChange}
                                                   channelChoice={channelChoice}/>
                        :
                        <CodeFormSection code={code} setCode={setCode}/>
                    }
                    <Button color={forms[formType].buttonColor}
                            text={t(forms[formType].buttonText)}
                            style={{borderRadius: '4px'}}
                    />
                    {formType === 'email' &&
                        <p style={{color: "red", marginTop: '.5rem'}}>{t('files.archiveNotice')}</p>
                    }
                </form>
            }
        </>
    )
}

export default AccidentFilesForm;
